import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  inject,
  isDevMode,
  provideAppInitializer,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  Router,
  TitleStrategy,
  provideRouter,
  withComponentInputBinding,
  withRouterConfig,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { authInterceptor } from '@interceptors/auth.interceptor';
import { handleErrorsInterceptor } from '@interceptors/handle-errors.interceptor';
import { loggerInterceptor } from '@interceptors/logger.interceptor';
import { naxVersionHeaderInterceptor } from '@interceptors/nax-version-header.interceptor';
import { serverDelayNotificationInterceptor } from '@interceptors/server-delay-notification.interceptor';
import { serverErrorNotificationInterceptor } from '@interceptors/server-error-notification.interceptor';
import Aura from '@primeng/themes/aura';
import * as Sentry from '@sentry/angular';
import { InitializationService } from '@services/initialization.service';
import { TitleStrategyService } from '@services/title-strategy.service';
import { providePrimeNG } from 'primeng/config';
import { APP_ROUTES } from './app.routes';
import { DefaultTranslateModule } from './app.translations.config';

const SentryErrorHandlerProvider = {
  provide: ErrorHandler,
  useValue: Sentry.createErrorHandler({
    showDialog: false,
  }),
};

const TraceServiceSentryProvider = {
  provide: Sentry.TraceService,
  deps: [Router],
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideServiceWorker('custom-ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SentryErrorHandlerProvider,
    TraceServiceSentryProvider,
    { provide: TitleStrategy, useClass: TitleStrategyService },

    provideRouter(
      APP_ROUTES,

      withComponentInputBinding(),
      //
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        urlUpdateStrategy: 'eager',
      }),
    ),

    providePrimeNG({
      theme: {
        preset: Aura,
        options: {
          darkModeSelector: false,
          cssLayer: {
            name: 'primeng',
            order: 'primeng, reset',
          },
        },
      },
    }),

    provideHttpClient(
      withInterceptors([
        authInterceptor,
        loggerInterceptor,
        handleErrorsInterceptor,
        serverErrorNotificationInterceptor,
        serverDelayNotificationInterceptor,
        naxVersionHeaderInterceptor,
      ]),
    ),
    importProvidersFrom(DefaultTranslateModule()),
    // TODO: find the way to remove it -> with PrimeNG, it is impossible to DELETE
    provideAnimations(),
    provideAppInitializer(() => inject(InitializationService).init()),
  ],
};
