import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@app/window.token';
import { LocalStorageEnum } from '@enums/local-storage.enum';
import { Nullable } from '@models/nullable.model';
import { AuthStateService } from '@state-management/auth-state';
import { MeConfigStateService } from '@state-management/me-config-state/me-config-state.service';
import { MePermissionsStateService } from '@state-management/me-permissions-state';
import { MeProfileStateService } from '@state-management/me-profile-state';
import { SystemStateService } from '@state-management/system-state';
import { isDateExpired } from '@utils/dates';
import { parseJWT } from '@utils/jwt-manipulation';
import {
  finalize,
  forkJoin,
  from,
  ignoreElements,
  Observable,
  take,
} from 'rxjs';
import { AuthService } from './auth.service';
import { OfflineIndexedDbService } from './indexed-db/entities/offline-indexed-db.service';
import { IndexedDBService } from './indexed-db/indexed-db.service';
import { MeService } from './me.service';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root',
})
// ! This service is strictly for initialization purposes.
// ! All initiation processes should be encapsulated inside a private function and added to the init method.
// Primary purpose is load essential configurations from local storage into the application state.
export class InitializationService {
  private readonly authStateService = inject(AuthStateService);
  private readonly authService = inject(AuthService);
  private readonly meService = inject(MeService);
  private readonly meConfigStateService = inject(MeConfigStateService);
  private readonly meProfileStateService = inject(MeProfileStateService);
  private readonly mePermissionsStateService = inject(
    MePermissionsStateService,
  );
  private readonly offlineIndexedDbService = inject(OfflineIndexedDbService);
  private readonly indexedDBService = inject(IndexedDBService);
  private readonly systemStateService = inject(SystemStateService);
  private readonly systemService = inject(SystemService);
  private readonly window = inject(WINDOW);
  private readonly document = inject(DOCUMENT);

  init(): Observable<Nullable<void>> {
    this.refreshTokenFromUrl();
    this.systemService.init();
    const initDB$ = this.populateInitDB();
    if (!this.checkRefreshToken()) return initDB$.pipe(ignoreElements());

    this.setMeConfig();
    this.setLimits();
    this.setCorporate();
    this.setPermissions();
    this.getIsOfflineState();
    this.setLastDownloadDate();
    this.setLoadLastDownloadHistogramsParamsFromLS();

    return forkJoin([
      initDB$,
      this.meService.initMeStates().pipe(take(1)),
    ]).pipe(ignoreElements());
  }

  private refreshTokenFromUrl(): void {
    const url = new URL(this.window.location.href);
    const refreshToken = url.searchParams.get('token');

    if (!refreshToken) return;
    localStorage.setItem(LocalStorageEnum.Refresh, refreshToken);
    this.window.history.replaceState(
      {},
      this.document.title,
      this.window.location.pathname,
    );
  }

  private populateInitDB(): Observable<IDBDatabase> {
    return from(this.indexedDBService.getDB()).pipe(
      take(1),
      finalize(() => this.offlineIndexedDbService.init()),
    );
  }
  private setLastDownloadDate(): void {
    const lastDownloadDate = localStorage.getItem(
      LocalStorageEnum.LastDownloadDate,
    );
    if (!lastDownloadDate) return;

    this.systemStateService.setValue('lastDownloadDate', +lastDownloadDate);
  }

  private setLimits(): void {
    const limits = localStorage.getItem(LocalStorageEnum.UserLimits);
    if (!limits) return;
    this.meProfileStateService.setValue('limits', JSON.parse(limits));
  }

  private setPermissions(): void {
    const permissions = localStorage.getItem(LocalStorageEnum.UserPermissions);
    if (!permissions) return;
    this.mePermissionsStateService.setMultipleValues(JSON.parse(permissions));
  }

  private setMeConfig(): void {
    const app_config = localStorage.getItem(LocalStorageEnum.AppConfig);
    if (!app_config) return;

    this.meConfigStateService.setMultipleValues(JSON.parse(app_config));
  }

  private checkRefreshToken(): boolean {
    const refreshToken = localStorage.getItem(LocalStorageEnum.Refresh);

    this.authService.setExpirationDateFromToken(refreshToken);

    const exp =
      (!!refreshToken && parseJWT(refreshToken, this.window.atob)?.exp) || null;
    this.authStateService.setValue('refresh', refreshToken);

    if (!isDateExpired(new Date().getTime(), exp)) return true;

    this.authService.logout();
    return false;
  }

  private setCorporate(): void {
    const corporate = localStorage.getItem(LocalStorageEnum.Corporate);
    if (!corporate) return;
    this.meProfileStateService.setValue('corporate_id', JSON.parse(corporate));
  }

  private getIsOfflineState(): void {
    const isOffline = localStorage.getItem(LocalStorageEnum.IsOffline);
    this.systemStateService.setValue('isManualOffline', isOffline === 'true');
  }

  private setLoadLastDownloadHistogramsParamsFromLS(): void {
    const lastDownloadHistogramsParams = localStorage.getItem(
      'lastDownloadHistogramsParams',
    );

    if (!lastDownloadHistogramsParams) return;

    this.systemStateService.setValue(
      'lastDownloadHistogramsParams',
      JSON.parse(lastDownloadHistogramsParams),
    );
  }
}
