import { effect, inject, Injectable, Injector, untracked } from '@angular/core';
import { AllIndexedDbStores } from '@models/indexed-db-stores.model';
import { SystemStateService } from '@state-management/system-state';
import { CoreIndexedDbService } from '../_core/core-indexed-db.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineIndexedDbService extends CoreIndexedDbService {
  private readonly isOffline = inject(SystemStateService).getValue('isOffline');
  private readonly injector = inject(Injector);

  readonly storeName: AllIndexedDbStores = 'offline';

  init(): void {
    effect(
      () => {
        const isOffline = this.isOffline();
        this.indexedDBService.onCloseDB();
        untracked(() => this.setIsOffline(isOffline));
      },
      { injector: this.injector },
    );
  }

  private async setIsOffline(value: boolean): Promise<number> {
    return this.indexedDBService.retryOperationDB(async () => {
      const db = await this.getDBConnection();

      return new Promise((resolve, reject) => {
        const transaction = db.transaction(this.storeName, 'readwrite');
        const store = transaction.objectStore(this.storeName);
        const request = store.put(value, 'isOfflineMode');

        request.onsuccess = (): void => {
          resolve(request.result as number);
        };

        request.onerror = (): void => {
          reject(request.error);
        };
      });
    });
  }
}
