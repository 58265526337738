import * as L from 'leaflet';

import 'leaflet-draw';
import 'leaflet.markercluster';

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';
import { environment } from '@environments/environment';
import { ServerUrlEnum } from '@models/environment.model';
import * as Sentry from '@sentry/angular';

Sentry.init({
  dsn: 'http://6e01a49f94dd3337832a7f3110297fef@sentry.naxsolutions.com/8',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: environment.name,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', ServerUrlEnum.Beta],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err, L.drawLocal.draw.handlers.polygon.tooltip.start),
);
