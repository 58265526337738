import { inject, Injectable, isDevMode, NgZone } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { WINDOW } from '@app/window.token';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SystemStateService } from '@state-management/system-state';
import {
  catchError,
  concat,
  filter,
  from,
  interval,
  mergeMap,
  Observable,
  of,
  Subscription,
  take,
  tap,
} from 'rxjs';
import { DialogService } from './dialog.service';
import { IndexedDBService } from './indexed-db/indexed-db.service';

@Injectable({
  providedIn: 'root',
})
export class CheckForUpdateService {
  private readonly translateService = inject(TranslateService);
  private readonly dialogService = inject(DialogService);
  private readonly systemStateService = inject(SystemStateService);
  private readonly isOffline = this.systemStateService.getValue('isOffline');
  private readonly ngZone = inject(NgZone);
  private readonly router = inject(Router);
  private readonly window = inject(WINDOW);

  private readonly canSyncData = inject(IndexedDBService).canSyncData;

  private intervalSubscription: Subscription | undefined;
  private readonly everyMinutes$ = interval(
    environment.minutes_interval_check_new_app_version * 60 * 1000,
  );
  private readonly everyMinutesOnceAppIsStable$ = concat(
    toObservable(this.isOffline),
    this.everyMinutes$,
  );

  private readonly swUpdate = inject(SwUpdate);

  start(): void {
    this.intervalSubscription?.unsubscribe();
    if (!this.swUpdate.isEnabled) return;

    if (isDevMode()) {
      console.info(
        `${this.getFormattedDateTime()} - ${this.translateService.instant('SERVICE_WORKER_DISABLED')}`,
      );

      return;
    }

    console.info(
      `${this.getFormattedDateTime()} - ${this.translateService.instant('SERVICE_WORKER_ENABLED')}`,
    );

    // TODO: remove runOutsideAngular if some day zonejs is removed
    this.ngZone.runOutsideAngular(() => {
      this.intervalSubscription = this.everyMinutesOnceAppIsStable$
        .pipe(
          filter(() => !this.isOffline()),
          mergeMap(() => {
            return this.checkForUpdate$().pipe(
              tap((updateFound) => {
                this.setIsUpdateAvailable(updateFound);

                this.manageUpdate(updateFound, this.canSyncData());
              }),
            );
          }),
        )
        .subscribe();
    });
  }

  private manageUpdate(updateFound: boolean, canSyncData: boolean): void {
    let message = 'YOU_HAVE_LAST_APP_VERSION';

    if (updateFound) {
      message = 'NEW_APP_VERSION_READY';
      this.applyUpdate(canSyncData);
    }

    console.info(
      `${this.getFormattedDateTime()} - ${this.translateService.instant(message)}`,
    );
  }

  private setIsUpdateAvailable(isUpdateDataAvailable: boolean): void {
    this.systemStateService.updateValue(
      'isUpdateAvailable',
      (value) => value || isUpdateDataAvailable,
    );
  }

  private checkForUpdate$(): Observable<boolean> {
    return from(this.swUpdate.checkForUpdate()).pipe(
      take(1),
      catchError((err) => {
        console.warn(
          `${this.getFormattedDateTime()} - ${this.translateService.instant('FAIL_CHECKING_NEW_APP_VERSION')}`,
          err,
        );
        return of(false);
      }),
    );
  }

  private getFormattedDateTime(): string {
    return new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(new Date());
  }

  private applyUpdate(canSyncData = false): void {
    let message = '',
      cancelLabelButton = 'UPDATE_LATER',
      cancelAction = (): void => {
        return;
      };

    if (canSyncData) {
      message = 'UPDATE_DATA_WITHOUT_SYNC';
      cancelLabelButton = 'GO_TO_SYNC';
      cancelAction = (): void => {
        this.router.navigate(['/home/settings/offline-mode']);
      };
    }

    this.dialogService.open({
      title: 'NEW_APP_VERSION_READY',
      message,
      confirmAction: this.reload.bind(this),
      confirmButtonLabel: 'UPDATE',
      cancelButtonLabel: cancelLabelButton,
      cancelAction,
    });
  }

  private reload(): void {
    this.swUpdate
      .activateUpdate()
      .then(() => {
        this.window.location.reload();
      })
      .catch((err) => console.error('Failed to apply updates: ', err));
  }
}
