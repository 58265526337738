import { HttpBackend } from '@angular/common/http';
import { ModuleWithProviders, Provider } from '@angular/core';
import { API_ROUTES } from '@constants/api-routes';
import { environment } from '@environments/environment';
import { LanguageFlags } from '@models/languages.model';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

export const DefaultTranslateModule =
  (): ModuleWithProviders<TranslateModule> =>
    TranslateModule.forRoot({
      defaultLanguage: LanguageFlags.es,
      useDefaultLang: true,
      loader: loaderFactory(),
    });

export const DefaultTranslateModuleTest =
  (): ModuleWithProviders<TranslateModule> =>
    TranslateModule.forRoot({
      defaultLanguage: LanguageFlags.es,
      useDefaultLang: true,
    });

const loaderFactory = (): Provider | undefined => {
  const environmentName = environment.name;
  if (environmentName === 'TEST') return undefined;

  return {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpBackend],
  };
};

// AoT requires an exported function for factories
export const HttpLoaderFactory = (
  httpHandler: HttpBackend,
): MultiTranslateHttpLoader => {
  return new MultiTranslateHttpLoader(httpHandler, [
    {
      prefix: `${environment.server_url}${environment.api_version}${API_ROUTES.static.translations}/`,
      suffix: '.json',
      optional: true,
    },
    { prefix: './i18n/', suffix: '.json' },
  ]);
};
