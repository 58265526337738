import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import packageJson from '../../../../../package.json';

const NAX_APP_VERSION_HEADER = 'Nax-App-Version';

export const naxVersionHeaderInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => next(addNaxVersionHeader(request));

const addNaxVersionHeader = (
  request: HttpRequest<unknown>,
): HttpRequest<unknown> => {
  if (!request.url.startsWith(environment.server_url + environment.api_version))
    return request;

  return request.clone({
    headers: request.headers.set(NAX_APP_VERSION_HEADER, packageJson.version),
  });
};
