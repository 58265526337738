import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorCodeType, errorCodes } from '@constants/error-codes';
import { environment } from '@environments/environment';
import { Nullable } from '@models/nullable.model';
import { SnackbarService } from '@services/snackbar.service';

interface ServerErrorBody {
  detail: {
    loc: [string, string, string];
    msg: string;
    type: string;
  }[];
}

export const httpErrorNotification = (
  snackbarService: SnackbarService,
  error: unknown,
): void => {
  if (!(error instanceof HttpErrorResponse)) return;

  const messages = errorMessageFactory(error);

  const isUnauthorizedError =
    error.status === HttpStatusCode.Unauthorized && !messages.length;
  const hasNoMessageInProduction =
    environment.name === 'BETA' && !messages.length;
  const skipNotification = isUnauthorizedError || hasNoMessageInProduction;

  if (skipNotification) return;
  if (!messages.length)
    return snackbarService.add({
      message: 'SERVER_ERROR',
      ...errorSnackbarConfig(error),
    });

  messages.forEach((message) => {
    setTimeout(() =>
      snackbarService.add({
        message: message,
        ...errorSnackbarConfig(error),
      }),
    );
  });
};

const errorMessageFactory = (error: HttpErrorResponse): string[] => {
  const localMessage = getLocalErrorMessage(error.url, error.status);
  const serverMessages = getServerErrorMessage(error);

  return localMessage ? [localMessage] : serverMessages;
};

const getLocalErrorMessage = (
  url: Nullable<string>,
  status: number,
): Nullable<ErrorCodeType['message']> => {
  return (
    errorCodes.find((e) => url && e.code === status && e.regExpUrl.test(url))
      ?.message ?? null
  );
};

const getServerErrorMessage = (error: HttpErrorResponse): string[] => {
  if (
    !error.url?.includes(environment.server_url) ||
    error.status !== HttpStatusCode.BadRequest
  )
    return [];

  const errorDetail = (error.error as ServerErrorBody)?.detail || [];

  return errorDetail.map((detail) => detail.type);
};

const errorSnackbarConfig = (error: HttpErrorResponse): object => ({
  messageParams: {
    status: error.status,
    url: error.url,
  },
  color: 'error',
});
